import jquery from "jquery";
window.$ = window.jQuery = jquery;
import { TweenMax, TimelineMax } from "gsap/TweenMax";
import ScrollMagic from "scrollmagic";
import "animation.gsap";
import "debug.addIndicators";
import "./slick.min";

let controller = new ScrollMagic.Controller();

$(".list").slick({
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  dots: false
});

$(".turn").click(function() {
  let turn = $(this).attr("data-turn");
  $(".turn").removeClass("active");
  $(this).addClass("active");
  $(".turn-content").hide();
  $(`.turn-content-${turn}`).show();
});

$(".sec-2-title, .sec-2-items").click(function() {
  $("#products").addClass("show");
  $("body").addClass("not-scroll");
});

$(".close").click(function() {
  $("#products").removeClass("show");
  $("body").removeClass("not-scroll");
});

$(window).on("load", function() {
  let tl1 = new TimelineMax();
  tl1
    .add("flag1")
    .to(".sec-2-title", 0.3, {
      opacity: 1,
      y: 0
    })
    // .to(".sec-2-circle", 0.3, { css: { className: "+=bounce" } }, "flag1+=0.3")
    // .to(".sec-2-items", 0.3, { css: { className: "+=bounce" } }, "flag1+=0.3")
    .to(".sec-2-circle", 0.25, { scale: 1.1, opacity: 1 }, "flag1+=0.3")
    .to(".sec-2-circle", 0.125, { scale: 0.8 })
    .to(".sec-2-circle", 0.125, { scale: 1 })
    .to(".sec-2-items", 0.25, { scale: 1.1, opacity: 1 }, "flag1+=0.3")
    .to(".sec-2-items", 0.125, { scale: 0.8 })
    .to(".sec-2-items", 0.125, { scale: 1 })
    .staggerTo(
      ".step",
      0.3,
      {
        opacity: 1,
        y: 0,
        stagger: {
          amount: 0.25
        }
      },
      "flag1+=0.6"
    );

  let tl2 = new TimelineMax();
  tl2
    .add("flag1")
    .to(".sec-3-title", 0.3, {
      opacity: 1,
      y: 0
    })
    .to(
      ".turns",
      0.3,
      {
        opacity: 1,
        y: 0
      },
      "flag1+=0.1"
    )
    .to(
      ".sec-3-text-1",
      0.3,
      {
        opacity: 1,
        y: 0
      },
      "flag1+=0.2"
    )
    .to(".tickets", 0.25, { scale: 1.1, opacity: 1 }, "flag1+=0.6")
    .to(".tickets", 0.125, { scale: 0.8 })
    .to(".tickets", 0.125, { scale: 1 })
    .to(
      ".sec-3-text-2",
      0.3,
      {
        opacity: 1,
        y: 0
      },
      "flag1+=0.8"
    );

  var scene1 = new ScrollMagic.Scene({
    triggerElement: ".sec-2",
    triggerHook: 0.5,
    reverse: false
  })
    // .addIndicators({
    //   colorTrigger: "white",
    //   colorStart: "white",
    //   colorEnd: "white",
    //   indent: 5
    // })
    .setTween(tl1)
    .addTo(controller);

  var scene2 = new ScrollMagic.Scene({
    triggerElement: ".sec-3",
    triggerHook: 0.5,
    reverse: false
  })
    // .addIndicators({
    //   colorTrigger: "white",
    //   colorStart: "yellow",
    //   colorEnd: "yellow",
    //   indent: 5
    // })
    .setTween(tl2)
    .addTo(controller);
});
